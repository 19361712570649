.container{
    
    /* overflow-x: hidden; */
    
    padding-right:5px;

    /* margin-top:-38px; */
}
.suggestions{
  overflow-y: auto;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:10px;
  min-width: 102px;
  height:400px;

  max-width: 200px;
}
.suggestions::-webkit-scrollbar {
  background: #FFFFFF;
  border-radius: 2px;
  height: 4px;
  width: 4px;
}

.suggestions::-webkit-scrollbar-thumb {
  background: #E0E0E0;
  border-radius: 2px;
}

  .suggestions > *{
    margin-top:10px;
  }
  .suggestions > *:first-child{
    margin-top:0px;
  }