.container{
    margin-top:25px;
    min-width: 102px;
}
.bookmarks{
    width:100%;
    /* overflow-x: hidden; */
    overflow-x: auto;
    padding-bottom:5px;
    display: flex;
    margin-top: 10px;
    
    
}

.bookmarks > *{
    margin-right:10px;
}
/* 
.bookmarks > *:first-child{
    margin-right:0px;
} */

.bookmarks::-webkit-scrollbar {
    background: #FFFFFF;
    border-radius: 2px;
    height: 4px;
    width: 4px;
  }
  
  
  .bookmarks::-webkit-scrollbar-thumb {
    background: #E0E0E0;
    border-radius: 2px;
  }

  .header{
      display: flex;
      margin-top: 5px;
  }
  .saveAll{
      margin-left: 10px!important;
  }
