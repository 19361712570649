.thumbnail{
    width:102px;
    height:102px;
    line-height: 102px;
    /* border-radius: 5px; */
    border: 1px solid #eeeeee;
    /* text-align: center; */
}

.thumbnail:hover{
    border: 1px solid #2196f3;
  }
  .selected{
    border: 1px solid #2196f3;
  }
  .deleteButton{
    position: absolute!important;
    transform: translate(0, 0)!important;
    margin:2px!important;
    background-color:rgba(255,255,255,0.7)!important;
    /* box-shadow: 10px 10px white; */
    transition:all 0.5s;
  }
  .deleteButton:hover{
    background-color:rgba(255,255,255,0.9)!important;
  }