.container{
    margin-left:35px;
    margin-right:10px;
    margin-top:20px;
}
.canvas{
    border: 1px solid #eeeeee;
    width:400px;
    height: 400px;
    
}

.menu{
    margin-bottom: 5px;
}