.container{
    margin:10px;
    margin-top:40px;
    /* margin-bottom:30px; */
    /* display: flex; */
}
.container > *{
    flex-basis: 0;
    flex-grow: 1;
}
.mainToolbar{
    display:flex;
}
.mainToolbar > *{
    margin:20px;
}
.textField{
    width:256px;
}
.fabButton{
    margin-left:40px!important;
}
/* .sideToolbar{
    display:flex;
} */
/* .sideToolbar > *{
    flex-basis: 0;
    flex-grow: 1;
} */

.message{
    margin-top: 10px;
    color: #ff1744;
}